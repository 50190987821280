import React, { useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';
//import authHeader from "./services/auth-header";
const SOCKET_URL = 'https://api.pixvisonz.com/v1/geo-websocket'


let token = "eyJraWQiOiI1Zk5ONXZncWFneXpZWXBHUHZjY2ZBMEw5all3U0FjU1BaM2JxVFUxQWVFPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI4Y2U3YzJmMi03OTg2LTQ4NTAtYTQ3Yi01YjJiMDc2YTM2YWIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX0NvVGZ1VXFTUiIsImNvZ25pdG86dXNlcm5hbWUiOiI4Y2U3YzJmMi03OTg2LTQ4NTAtYTQ3Yi01YjJiMDc2YTM2YWIiLCJhdWQiOiI0MnVjM2ZnNnVtdGZqanJvN3BvdGkxazEzcSIsImV2ZW50X2lkIjoiZDU2OTlkOTAtN2IzZC00MTc4LTgzYjAtMjdlMTY1ZDU4ZDBhIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2Nzg3MDE4ODIsIm5hbWUiOiJBdXRvbWF0ZW5vdyIsImV4cCI6MTY3ODc4ODI4MiwiaWF0IjoxNjc4NzAxODgyLCJlbWFpbCI6ImF1dG9tYXRlbm93LmNvQGdtYWlsLmNvbSJ9.WUkxXOf5RwMFqCbkqArURkiv_QvzGizzmdUmehfrdHSe6Zh_yAEE9A-l8m6HInxQ9X8DfPf0cdWfYcOS_46hO6sM8hLTTmv0FRQ0lDqNinddWtzne6TK_T0VXwxXwDVvL4wUB04KbXOhrUEERF7w3wBifUoI30swLhl9q481J5yyyCf0zRIIfYdRsIcgRZOXwL7dCLtvsAWK2uAS9yicaI4NnxI-i4rHht_KfFe9-4wuGkTFZGmY4T23QzfWxJkswbWeMkaED5voZnmL-vY09OQtTUV3APTa-rHzRkosTix-0fKhZsnePZtouKqSLjnQwCK1z-r17NAKyXqXRE1XBA"

//let authHeader = { Authorization: 'Bearer ' + token };

function authHeader() {
    return { Authorization: 'Bearer ' + token };
}

const MapWebSocket = (props) => {
  const [message, setMessage] = useState('You server message here.');
  const [topics, setTopics] = useState([]);

  let onConnected = () => {
    console.log("Connected to websocket!!")
    setTopics(['/litter/geolocations']);
  }

  let onMessageReceived = (msg) => {
      //console.log("onMessageReceived", JSON.stringify(msg));
      props.onChange(msg);
  }

  useEffect(() => {
    //console.log(authHeader().Authorization);
  }, [])

 /*  if(topics.length == 0){
    return null;
  }
  else{
    return (
      <div>
        <SockJsClient
          subscribeHeaders={authHeader()}
          url={SOCKET_URL}
          topics={['/litter/geolocations']} //['/litter/geolocations']
          onConnect={onConnected}
          onMessage={msg => onMessageReceived(msg)}
          debug={false}
        />
      </div>
    );
  } */
  return (
    <div>
      <SockJsClient
        subscribeHeaders={authHeader()}
        url={SOCKET_URL}
        topics={['/litter/geolocations']}
        onConnect={onConnected}
        onMessage={msg => onMessageReceived(msg)}
        debug={false}
      />
    </div>
  );
  
}

export default MapWebSocket;