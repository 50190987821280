import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colorConfigs from '../configs/colorConfigs';
import { Card, CardContent } from '@mui/material';
import Logo from "../Assets/ctm-01.png"
import Copyright from './Copyright';
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/auth"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ToastController from '../Controller/ToastController/ToastController';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid')
});

export default function ForgotPassword() { 
    const { register, handleSubmit, formState: { errors } } = useForm({resolver: yupResolver(validationSchema)});
    const navigate = useNavigate();
    const [successBox, setSuccessBox]  = React.useState(false);
    const [toastMsg, setToastMsg]= React.useState({
      msg: ``,
      status: ``
    });

    const handleForgotPassword = (data) => {
        AuthService.forgotPassword(data).then(resp => {
            console.log(JSON.stringify(resp));
            navigate("/signin");
        }).catch(err => {
          console.log(JSON.stringify(err));
            setSuccessBox(true)
            setToastMsg((prevState) => ({
                ...prevState,
                msg:  `Username is invalid`,
                status: 'error'
            }));
        })
      }

      const handleError = (errors) => {
        console.log(errors)
        let e = errors && errors.email && errors.email.message;
        let p =  errors && errors.password && errors.password.message;
        let x = e ? e : p ? p : "Username is invalid"
      
        setSuccessBox(true)
        setToastMsg((prevState) => ({
          ...prevState,
          msg:  x,
          status: 'error'
        }));
    };

    const onClose =(e)=>{
        setSuccessBox(false)
      }

    return(
        <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor: colorConfigs.mainBg, height: `100vh`}}
      >
          <Card sx={{ maxWidth: 500, minWidth: 400 }}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img src={Logo} width={60}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                  <Typography component="h1" variant="body2" style={{textAlign: `center`}}>
                    Forgot your Password?
                  </Typography>
              </Grid>
              <form onSubmit={handleSubmit(handleForgotPassword, handleError)}>
                <Grid container spacing={2}  style={{padding: `10px`}}> 
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            {...register('email')}
                            fullWidth
                        />
                    </Grid>                     
                </Grid>                    
              </form>
              <Grid item xs={12} md={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained">
                    Recover Password
                  </Button>
              </Grid>
              <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center" style={{padding: `10px`}}
                spacing={2}>
                <Grid item xs={4} sm={3} md={3}>
                  <Link onClick={()=>  navigate("/signin")} variant="body2">
                    {"Sign In"}
                  </Link>
                </Grid>
                <Grid item xs={8} sm={9} md={9}>
                  <Link onClick={()=>  navigate("/signup")} variant="body2"  style={{float: 'right'}}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                  <Copyright />
              </Grid>
            </CardContent>
          </Card>
          {successBox &&(
            <ToastController 
            isOpen={successBox}
            onClose={onClose}
            message={toastMsg.msg}
            status={toastMsg.status}/>
          )}
      </Grid>
    )
}